import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    Snackbar,
    Stack,
    TextField,
    Radio,
    Typography,
    useMediaQuery,
    useTheme, Chip
} from "@mui/material";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import * as React from "react";
import SessionDialog from "../components/sessionDialog";
import {version} from "../config";
import {API_URL} from "../config";

const Home = () => {

    const [frase, setFrase] = useState('');
    const [targets, setTargets] = useState()
    const [sentiment, setSentiment] = useState(3);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const handleCloseSuccess = () => setOpenSuccess(false);
    const handleCloseError = () => setOpenError(false);
    const theme = useTheme();
    let {event, table} = useParams();

    const docRef = firestore.doc('events/' + event);
    const [config, configLoading, configError] = useDocument(docRef);
    const [open, setOpen] = useState(false);
    const [sending, setSending] = useState(false);
    const [session, setSession] = useState(1)
    const [lastSession, setLastSession] = useState(1)
    const [tema, setTema] = useState({})

    useEffect(() => {
        if(parseInt(table) <= 7){
            setTema({name: 'Equità', color:'#E3C19C'})
            setSession(1)
        } else if (parseInt(table) > 7 && parseInt(table) <= 14){
            setTema({name: 'Innovazione', color:'#B6D2DB'})
            setSession(2)
        } else if (parseInt(table) > 14 && parseInt(table) <= 21){
            setTema({name: 'Sostenibilità', color:'#F2D6D7'})
            setSession(3)
        }
    }, [])

    useEffect(() => {
        if (config && config.data().current_session !== lastSession) {

            setFrase('');
        }
    }, [config])

    useEffect(() => {
        if (session) {
            // Recupero lista domande
            firestore.doc('events/' + event + '/sessions/' + session).collection('questions').get().then((querySnapshot) => {
                let questions = [];
                querySnapshot.forEach((doc) => {
                    questions.push(doc.data());
                });
                setQuestions(questions);
            })
        }
    }, [session])

    const md = useMediaQuery(theme.breakpoints.up('md'))

    const handleChangeSession = (number) => {
        if (number !== session && number <= lastSession) {
            setSession(number);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function sendData() {
        setSending(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({sentence: frase, table: table, session: session, question: currentQuestion})
        };
        fetch(API_URL + "events/" + event + "/sentences/" + session, requestOptions)
            .then(response => response.json())
            .then(data => {
                setFrase('');
                setSentiment(3);
                setCurrentQuestion(null);
                setTargets();
                setOpenSuccess(true)
                setSending(false)
            })
            .catch(error => {
                setOpenError(true)
                setSending(false)
            });
    }

    return <Stack sx={{
        background: theme.palette.background.main, width: '100%', minHeight: '100dvh'
    }} direction={"column"}
                  justifyContent={"center"} alignItems={"center"}>
        <Container maxWidth={'xl'}>
            <Stack direction={"column"} sx={{px: 5}}>
                <Stack container sx={{width: '100%', mt: 2, mb: 2, textAlign: 'left'}} spacing={2} alignItems={"center"}
                       direction={md ? "row" : "column"} justifyContent={"space-between"}>
                    <Box sx={{flexShrink: 0}}>
                        {!configLoading && !configError && config &&
                            <img src={config.data().url_logo} style={{maxWidth: '8rem', width: '100%'}}/>}
                    </Box>
                    {!configLoading && !configError && config && config.data().message.length > 0 &&
                        <Alert severity={"warning"} variant={"filled"}>
                            {config.data().message}
                        </Alert>}
                    <Box sx={{flexShrink: 0}}>
                        <Typography color={theme.palette.primary.main} textAlign={"right"} component={"p"}
                                    variant={"h4"} fontWeight={"bold"}>Tavolo {table}</Typography>
                    </Box>
                </Stack>
                <Grid container spacing={6} alignContent={'start'}>
                    <Grid item xs={3}>
                        <FormControl>
                            <FormLabel>Tema</FormLabel>
                            <Stack direction={"column"} alignItems={"start"} sx={{mt: 1}} spacing={2}>
                                {Object.keys(tema).length>0 && <Chip sx={{background: tema.color, color:'black', fontSize:'1.1rem', padding:'0.6rem'}} label={tema.name}/>}
                            </Stack>
                        </FormControl>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl>
                            <FormLabel>Step di discussione:</FormLabel>
                            <RadioGroup
                                name="radio-buttons-group"
                                value={currentQuestion}
                            >
                                {questions.map((question, index) => (
                                    <FormControlLabel key={index}
                                                      value={index}
                                                      control={<Radio onClick={(e) => {
                                                          if (currentQuestion == index) {
                                                              setCurrentQuestion(null)
                                                          } else {
                                                              setCurrentQuestion(index)
                                                          }
                                                      }}/>}
                                                      label={question.content}/>)
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <TextField
                    id="frase"
                    label="Inserisci la frase qui"
                    multiline
                    minRows={4}
                    sx={{mt: 1}}
                    value={frase}
                    onChange={(event) => setFrase(event.target.value)}
                />

                <Box sx={{width: '100%', textAlign: 'center', mb: 0}}>
                    {sending ? <Box sx={{mt: 2, pb: '1px', pt: '1.75px'}}><CircularProgress/></Box> :
                        <Button disabled={frase.length === 0} size="large" variant={"contained"}
                                onClick={sendData}
                                sx={{mt: 3, width: '10rem'}}>
                            invia
                        </Button>}
                </Box>

                <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                    <Typography variant={"body2"}>v. {version}</Typography>
                </Stack>
            </Stack>
        </Container>

        <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
            <Alert elevation={6} variant={"filled"} onClose={handleCloseSuccess} severity="success"
                   sx={{width: '100%'}}>
                Frase inviata con successo
            </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={4000} onClose={handleCloseError}>
            <Alert elevation={6} variant={"filled"} onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                Errore durante l'invio, si prega di riprovare
            </Alert>
        </Snackbar>
        <SessionDialog open={open} handleClose={handleClose}/>
    </Stack>
}

export default Home;
